<template>
  <div class="download-wrapper">
    <div class="download-container">
      <transition>
        <div class="button" @click="onDownloadAndroid">
          <img src="@/assets/icon/ic_android_blue.png" alt="android">
          <span>Android 版下载</span>
        </div>
      </transition>
      <!--      <div class="button">-->
      <!--        <image src="@/assets/icon/ic_ios_blue.png" alt="ios">-->
      <!--        <span>&nbsp;&nbsp;&nbsp;IOS 版下载&nbsp;&nbsp;&nbsp;</span>-->
      <!--      </div>-->
      <div class="stay-tuned">IOS版 敬请期待...</div>
    </div>
<!--    <div-->
<!--      class="guide-container"-->
<!--      v-if="isFirstIn === true && isIOS === false && isWxEnv === true"-->
<!--      @click.prevent.stop="onGuideClick">-->
<!--      <img-->
<!--        class="guide"-->
<!--        src="@/assets/image/pic_wx_download_guide.png"-->
<!--        alt="guide"/>-->
<!--    </div>-->
  </div>
</template>

<script>

// import { getCurrentInstance, onMounted, ref } from 'vue'
import { ref } from 'vue'

export default {
  name: 'Download',
  setup () {
    // const { proxy } = getCurrentInstance()

    const isFirstIn = ref(true)

    const isIOS = ref(/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent))

    const isWxEnv = ref(/(MicroMessenger)/i.test(navigator.userAgent))

    // let versionInfo = null
    //
    // onMounted(() => {
    //   getVersionInfo()
    // })
    //
    // const getVersionInfo = () => {
    //   proxy.$api.app.versionInfo().then(res => {
    //     if (res.data.code === 0) {
    //       const info = res.data.data.value
    //       versionInfo = JSON.parse(info)
    //     }
    //   })
    // }

    const onDownloadAndroid = () => {
      if (isIOS.value === true) {
        alert('IOS版 敬请期待...')
        return
      }
      // window.location.href = versionInfo?.url ? versionInfo.url : 'https://files.greatech.com.cn/latest.apk'
      window.location.href = 'https://files.greatech.com.cn/app/latest.apk'
      // window.location.href = 'https://a.app.qq.com/o/simple.jsp?pkgname=com.greatech.android'
      // window.location.href = 'http://m.greatech.com.cn:8282/app/latest.apk'
      // window.location.href = 'https://files.greatech.com.cn/latest.apk'
    }

    const onGuideClick = () => {
      isFirstIn.value = false
      isWxEnv.value = false
    }

    return {
      isFirstIn,
      isIOS,
      isWxEnv,
      onGuideClick,
      onDownloadAndroid
    }
  }
}
</script>

<style lang="scss" scoped>
.download-wrapper {
  width: 100%;
  height: 100%;
  background-color: #227CFF;

  .download-container {
    width: 100%;
    height: 100%;
    background: url('../../assets/image/bg_download.png') no-repeat;
    background-size: 100% auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 120px;

    .button {
      min-width: 260px;
      height: 54px;
      background: #ffffff;
      border-radius: 26px;

      font-size: 20px;
      font-weight: 500;
      color: #227cff;

      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 20px;

      &:not(:first-child) {
        margin-top: 20px;
      }

      &:active {
        opacity: 0.8;
      }

      img {
        width: 24px;
      }

      span {
        margin-left: 20px;
      }
    }

    .stay-tuned {
      margin-top: 20px;
      color: white;
    }
  }

  .guide-container {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 4, 18, 0.8);

    .guide {
      width: calc(100% - 40px);
      /* height: 100%; */
      position: absolute;
      top: 20px;
      left: 20px;
    }
  }

  //.bg {
  //  background: url('../../assets/pic_download_bg.png');
  //  background-size: 100%;
  //}

  .logo {
    width: 74px;
    height: 74px;
    margin-top: 140px;
  }
}
</style>
